import React, { Component } from 'react';
import RedLine from '../element/RedLine';
import Project from '../element/Project';
// import ProjectDetails from '../element/ProjectDetails';
import TeamWork from '../img/TeamWork.png';
import GateApp from '../img/GateApp.png';
import Spring from '../img/Spring.png';
import Fruity from '../img/Fruity.png';
import Fazako from '../img/Fazako.png';
//import RuthKolours from '../img/RuthKolours.png';
import Aika from '../img/Aika.png';
import Fousa from '../img/FousaEnP.png';
import Delve from '../img/Delve.png';
import Medissah from '../img/medissahImg.png';
import Greenbag from '../img/greenbagImg.png';
import Pcn from '../img/PCN.png';
// import Mbs from '../img/Mbs.png';
import VerticalSpace from '../element/VerticalSpace';
// import Menu from '../layout/Menu';



export class ProjectsPage extends Component {
    
   
    render() {
        return (
            <div className='projectsContainer' id='projects'>
              { /*  <Menu /> */}
                <VerticalSpace spaceHeight='2.5em'></VerticalSpace>
           <section>
           <h3>Recent Projects</h3>
           <RedLine 
           redWidth='14em'/>
           </section>
      {/* <section >
            <ul >
                <li>All |</li>{' '}
                <li>Web apps |</li>{' '}
                <li>Websites </li>{' '}
                <li>Mobile apps</li>
            </ul>
        </section>*/ }
        <VerticalSpace
        spaceHeight='3em' />
               <section className='projectGrid'>

               <Project 
                   client={Medissah}
                   projectName = {<a target='_blank' rel="noopener noreferrer" href='https://medissah.com/'>Medissah WebApp</a>} 
                   link = {<a target='_blank' rel="noopener noreferrer" href='https://medissah.com/'>Visit Site</a>} 
                  clientDetails = 'A medical marketplace to connect health providers to clients'
                  projectDetails ='Fullstack App Built With Laravel'/> 

                  <Project 
                   client={Greenbag}
                   projectName = {<a target='_blank' rel="noopener noreferrer" href='https://greenbaghq.com/'>GreenBag WebApp</a>} 
                   link = {<a target='_blank' rel="noopener noreferrer" href='https://greenbaghq.com/'>Visit Site</a>} 
                  clientDetails = 'An investment and savings management system'
                  projectDetails ='Fullstack App Built with Laravel'/> 
                  
                       <Project
                  client={TeamWork}
                  projectName = {<a target='_blank' rel="noopener noreferrer" href='https://teamwork-frontend.firebaseapp.com/'>TeamWork WebApp</a>}
                  link = {<a target='_blank' rel="noopener noreferrer" href='https://teamwork-frontend.firebaseapp.com/'> Visit Site</a>}
                  clientDetails = 'Teamwork is an internal social network for employees of an organization.'
                  projectDetails ='Frontend: React.js, Backend: Node.js and Express, Postgres DB'
                  footnote ='Test with - Username:adminUser@admin.com Password:adminPassword'
                 />
                  
                       <Project
                  client={GateApp}
                  projectName = {<a target='_blank' rel="noopener noreferrer" href='https://gateguard.co/'>Gateguard App</a>}
                  link = {<a target='_blank' rel="noopener noreferrer" href='https://gateguard.co/'>Visit Site</a>}
                  clientDetails = 'An Estate Management solution for estates and gated communities'
                  projectDetails ='Backend: Laravel, MySql DB, WebApp Frontend:HTML,CSS,Vanilla JS'
                  footnote ='*built with a team'
                  />

                  <Project 
                   client={Aika}
                   projectName = {<a target='_blank' rel="noopener noreferrer" href='http://aikapremium.com/'>Aika Load</a>}
                   link = {<a target='_blank' rel="noopener noreferrer" href='http://aikapremium.com/'>Visit Site</a>}
                  clientDetails = 'Logistics Management Marketplace Web App'
                  projectDetails ='Frontend- HTML, CSS(SASS), Bootstrap, Vanilla Javascript' />
                  
                   <Project
                   client={Spring} 
                   projectName = {<a target='_blank' rel="noopener noreferrer" href='https://springinteriors.ng/'>Spring Interiors Website</a>}
                   link = {<a target='_blank' rel="noopener noreferrer" href='https://springinteriors.ng/'>Visit Site</a>}
                  clientDetails = 'Coporate website design for 2019 African properties award winner'
                  projectDetails ='Built with WordPress'
                   />
                   <Project 
                   client={Fruity}
                   projectName = {<a target='_blank' rel="noopener noreferrer" href='http://www.fruitybe.com/'>FruityBe Website</a>}
                   link = {<a target='_blank' rel="noopener noreferrer" href='http://www.fruitybe.com/'>Visit Site</a>}
                  clientDetails = 'Coporate website design for branding firm'
                  projectDetails ='Built with WordPress'/>

                  <Project 
                   client={Fousa}
                   projectName = {<a target='_blank' rel="noopener noreferrer" href='http://fousaeandp.com/'>Fousa E and P Website</a>}
                   link = {<a target='_blank' rel="noopener noreferrer" href='http://fousaeandp.com/'>Visit Site</a>}
                  clientDetails = 'Corporate website design for an Oil and Gas Exploration Firm'
                  projectDetails ='Built with WordPress'/>

                  <Project 
                   client={Pcn}
                   projectName = {<a target='_blank' rel="noopener noreferrer" href='https://pestcontrolnigeria.com/'>Pest Control Nigeria</a>} 
                   link = {<a target='_blank' rel="noopener noreferrer" href='https://pestcontrolnigeria.com/'>Visit Site</a>} 
                  clientDetails = 'Coporate website and Ecommerce store for a pest control service company'
                  projectDetails ='Built with WordPress'/> 

                  <Project 
                   client={Delve}
                   projectName = {<a target='_blank' rel="noopener noreferrer" href='https://delve.ng/'>Delve Ng</a>}
                   link = {<a target='_blank' rel="noopener noreferrer" href='https://delve.ng/'>Visit Site</a>}
                  clientDetails =  'An online directory of businesses offering quality products and services, in Nigeria'
                  projectDetails ='Built with WordPress'/>
                  
                 {/* <Project 
                   client={RuthKolours}
                   projectName = {<a target='_blank' rel="noopener noreferrer" href='https://ruthkolours.com/'>Ruth Kolours</a>} 
                   link = {<a target='_blank' rel="noopener noreferrer" href='https://ruthkolours.com/'>Visit Site</a>} 
                  clientDetails = 'E-Commerce Store for RuthKolours that deals in wigs'
                  projectDetails ='Built with Wordpress'/> */}
                 
                  <Project 
                   client={Fazako}
                   projectName = {<a target='_blank' rel="noopener noreferrer" href='http://fazakogeoformatics.com/'>Fazako Geoformatics Website</a>} 
                   link = {<a target='_blank' rel="noopener noreferrer" href='http://fazakogeoformatics.com/'>Visit Site</a>} 
                  clientDetails = 'Coporate website design for a GIS consulting firm'
                  projectDetails ='Built with WordPress'/> 

                

                 


               </section>
               <section style={{display:'none'}}>
                   <h3>View Other Projects {'>>'} </h3>
               </section>
                
               <VerticalSpace />
                
            </div>
        )
    }
}

export default ProjectsPage
