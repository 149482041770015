import React from 'react';
import VerticalSpace from '../element/VerticalSpace';

export default function ProjectDetails(props) {
    return (
        <div className='project projectDetails' 
        onMouseEnter={props.hover} onMouseLeave={props.hover} 
       >
            <h2>
                {props.projectName}
                <span >
                <p style={{margin:'auto',color:'#FF0513'}}>{props.link}</p>
                </span>
            </h2>
            <VerticalSpace spaceHeight='0.5em' />
            <p>
                {props.clientDetails}
               
            </p>
            <VerticalSpace spaceHeight='1.5em' />
            <h4>
                {props.projectDetails}
             
            </h4>
            <VerticalSpace spaceHeight='0.5em' />
            <p>
            {props.footnote} 
            </p>
        </div>
    )
}
