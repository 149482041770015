import React, { Component } from 'react';
import Skill from '../element/Skill'

export class CapabilitySection extends Component {
    render() {
        return (
            <React.Fragment>
            <h2> My Capabilities</h2>
            <span className='redLine'></span>
            <div className='skillGrid'>
            <Skill 
                name= 'Backend Development'
                description='System architecture and design planning, Data structures and algorithms, REST APIs'
                />
                <Skill 
                name = 'Database Management'
                description='Database design and query for MySQL,Postgres and MongoDB'/>
                <Skill 
                name='Frontend Development'
                description='Responsive web designs, ability to recreate designs, attention to details'/>
                <Skill 
                name = 'Wordpress Development'
                description='Skilled manipulation of wordpress templates, template development and plugin developement'
                />
                <Skill 
                name = 'Test Driven Development'
                description='Unit Tests with Jest and Mocha test suite'
                />
               
                <Skill 
                name ='Motion Graphics Animation '
                description='Logo Animation, Ident design, video editing'/>

            </div>
            </React.Fragment>
        )
    }
}

export default CapabilitySection
