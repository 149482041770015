import React, { Component } from 'react'
import ProjectDetails from './ProjectDetails'

export class Project extends Component {
    constructor(props){
        super(props);
            this.state = {
                hoverActive: false,
                
        }
    }
    toggleHoverState = (state) =>{
        return {
            hoverActive: !state.hoverActive,
        };
    }
  

   projectHover = () => {
       this.setState(this.toggleHoverState)

   }
    render() {
        return (
            <div>
                { !this.state.hoverActive ?
                    <div className='project' 
        style={{backgroundImage:`url(${this.props.client})`}}
        onMouseEnter={this.projectHover} onMouseLeave={this.projectHover}>
            
        </div>: <ProjectDetails  
        hover={this.projectHover}
        projectName = {this.props.projectName}
        clientDetails={this.props.clientDetails}
        projectDetails ={this.props.projectDetails}
        footnote={this.props.footnote}
        link={this.props.link}/>
    }   
            </div>
        )
    }
}

export default Project
