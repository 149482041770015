import React from 'react';
import Instagram from '../img/insta.svg';
import LinkedIn from '../img/linked.png';
import Twitter from '../img/Twitter.svg';



export default function Social(props) {
    return (
        
        
        <div className='socialNav'>
       <p>{props.title}</p>
       <div className='socialIcons'>
       <a target='_blank' rel="noopener noreferrer" href='https://www.linkedin.com/in/oluwamayowa-fadairo-6460b282'>
       <img  src={LinkedIn} alt='linkedin'/>
       </a>{' '}
       <a target='_blank' rel="noopener noreferrer" href='https://twitter.com/Mawhizzle'>
       <img src={Twitter} alt='twitter'></img>
       </a>{' '}
       <a target='_blank' rel="noopener noreferrer" href='https://instagram.com/Mawhizzle'>
           <img src={Instagram} alt='instagram'></img>
           </a>
           
           
           
       </div>
       
   </div>
       
        
    )
}
