import React, { Component } from 'react';
import Menu from '../layout/Menu'
import GithubLogo from '../img/github.svg';
import Social from '../layout/Social';
import {Link} from 'react-scroll';
import Arrow from '../element/Arrow';
import DownArrow from '../img/downArrow.svg';
import VerticalSpace from '../element/VerticalSpace';


export class Homepage extends Component {
    render() {
        return (
            <div className='homeContainer' id='homepage'>
            <Menu />     
            <div className='contentWrapper'>
            <aside className='leftAside'>
            <h6 className='orange'>Hello, I am</h6>
            <h1>Oluwamayowa <br />Fadairo</h1>
            <h5>A Software Engineer</h5>
            <Link to='contactme'
            smooth={true}
            duration= {2000}>
            <button className='contact'>Contact</button></Link>
            
            </aside>
            <aside className='rightAside'>
            <a target='_blank' rel="noopener noreferrer" href='https://github.com/OluwmayowaF'>
            <p><img width='16em'src={GithubLogo} alt='github'/><span className='smallSpace' />View Profile</p>
            </a>
            </aside>
           
            </div>
            <Link
        to='aboutme'
        smooth={true}
        duration={700}
        spy={true}> <span className='scrollDown'> <p>Scroll down</p></span></Link>
             <div className='footer'>
                 <Arrow arrow = {<img  width='60%' src={DownArrow} alt='arrow' />}/>
                 <Social title='Social' />  
                 
                 </div>   
                 <VerticalSpace spaceHeight='0.5em' />
            </div>
        )
    }
}

export default Homepage
