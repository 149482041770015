import React from 'react'

import {Link} from 'react-scroll';

export default function Arrow(props) {
    return (
        <div><div className='downArrow'>
        
        <Link
        to='aboutme'
        smooth={true}
        duration={700}
        spy={true}>
        {props.arrow}</Link>
        </div>
            
        </div>
    )
}
