import React from 'react';

import VerticalSpace from '../element/VerticalSpace';
import Social from '../layout/Social';
import {Link} from 'react-scroll'; 

export default function Footer() {
    return (
        <div className='footerContainer'>
            <span className='verticalLine'></span>
        
            <ul className='details'>
                <li>
                <Link
                activeClass ='selected'
                to="homepage"
                spy={true}
                smooth={true}
                duration= {700}>
                    Home |
                    </Link>
                   </li>
                   {' '}
                <li>
                <Link
                to='aboutme'
                activeClass='selected'
                smooth={true}
                spy={true}
                duration={500}>
                About |
                </Link>
                </li>
                {' '}
                <li>
                    <Link
                    to='projects'
                    smooth={true}
                    spy={true}
                    duration={500}>
                    Projects |</Link></li>
                {' '}
                <li>
                <Link
                to='contactme'
                smooth = {true}
                spy ={true}
                duration ={500}>
                Contact 
                </Link></li>
                
            </ul>
           
            <VerticalSpace 
            spaceHeight='0.5em'/>

<p className='details'>{/*NG /<a href='tel:+2349057894282'> +234 905 789 4282 </a>/*/}<a href='mailto:e_fadairo@yahoo.com?subject=Hey Mayowa'> e_fadairo@yahoo.com</a></p>
<VerticalSpace 
            spaceHeight='1em'/>

           <Social />
           <VerticalSpace 
            spaceHeight='1em'/>
            <span className='footerLine'></span>
            <section>
                <p className='copyright'>Copyright © 2020 Mayowa Fadairo | All rights reserved</p>
            </section>
            
        </div>
    )
}
