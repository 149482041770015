import React, { Component } from 'react';

import Mayowa from '../img/Mayow.jpeg'
import CapabilitySection from '../layout/CapabilitySection';
import StackSection from '../layout/StackSection';
import VerticalSpace from '../element/VerticalSpace';


export class AboutMePage extends Component {
    render() {
        return (
            <div className='aboutContainer' id='aboutme'>
            { /*<Menu />*/ }
            <VerticalSpace />
                <div className='contentWrapper'>

                <aside className='leftAside'>
            <h3>About Me</h3>
            <p>I am a problem solver, passionate about building efficient, 
                robust and highly scalable systems with a user centric approach.
            
                <br />Lets create something <span style={{borderBottom: '2px solid red'}}>iconic</span> together!
            </p>
           
            
          {/*  <button  className='contact'>
                      <a target='_blank' rel="noopener noreferrer" href ='https://drive.google.com/file/d/1H-MF95HaG4wusWDKkslH-YSPbKVUnvqj/view?usp=sharing'>  View CV</a>
                    </button> */}
            </aside>
            <aside className= 'rightAside'>
            <img className='mayowa' src={Mayowa} alt='Mayowa'></img>
            </aside>
                </div>
            <VerticalSpace />
            <section className='capabilities'>

            <CapabilitySection />
            </section>
            <section className='stacks'>
                <StackSection />
            </section>

           <VerticalSpace />
            </div>
        )
    }
}

export default AboutMePage
