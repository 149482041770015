import React from 'react'

export default function Stack(props) {
    return (
        <div className='stack'>
            
           { // <div className='stackLevel' style={{width:props.level}}>
                <div className='stackName' >
                    <h5>{props.name}</h5>
                </div>

            // </div> 
           }
        </div>
    )
}
