import React from 'react';
import './App.css';
import Homepage from './pages/Homepage';
import AboutMePage from './pages/AboutMePage';
import ProjectsPage from './pages/ProjectsPage';
import ContactPage from './pages/ContactPage';
import Footer from './layout/Footer';

function App() {
  return (
    <div >
  
     <Homepage />
    
     <AboutMePage />
     
     
     <ProjectsPage />
    
    
     <ContactPage />
     <Footer />
    </div>
  );
}

export default App;
