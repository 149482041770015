import React from 'react'
import {Link} from 'react-scroll';

export default function Menu() {
    return (
        <nav className='menuWrapper'>
           
            <ul className='menuitem'>
                <li>
                <Link
                activeClass ='selected'
                to="homepage"
                spy={true}
                smooth={true}
                duration= {700}>
                    Home
                    </Link>
                   </li>
                <span className='space'></span>
                <li>
                <Link
                to='aboutme'
                activeClass='selected'
                smooth={true}
                spy={true}
                duration={500}>
                About
                </Link>
                </li>
                <span className='space'></span>
                <li>
                    <Link
                    activeClass ='selected'
                    to='projects'
                    smooth={true}
                    spy={true}
                    duration={500}>
                    Projects</Link></li>
                <span className='space'></span>
                <li>
                <Link
                activeClass ='selected'
                to='contactme'
                smooth = {true}
                spy ={true}
                duration ={500}>
                Contact
                </Link></li>
                
            </ul>
           
            
            
        </nav>
    )
}
