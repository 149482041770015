import React, { Component } from 'react'
import VerticalSpace from '../element/VerticalSpace'


export class ContactPage extends Component {
    render() {
        return (
            <div className='contactContainer' id='contactme'>
                <span className='verticalLine'></span>
                <h3>Let’s Create Something<br />
Iconic Together</h3>
<p>Hey! Remember that idea that you have been working on?<br /> Yeah it's 
    time to start. Hit Me up. 
</p>
 <span style={{padding:'2em'}}></span>
<section className='contactMe' >
    <form  >
        <div style={{display:'none'}} className='formGroup'>
            <input type='text'
            placeholder='Name' />
            <input type='text'
            placeholder='Email' />
        </div>
        <div style={{display:'none'}} >
        <input type='text'
            placeholder='Phone#' />
            <input type='text'
            placeholder='Budget' />
            
        </div>
        <div style={{display:'none'}} >
        <textarea type='textarea'
            defaultValue='Message' />
            
            
        </div>
        <span style={{padding:'2em'}}></span>
        <div >
        <a href="mailto:e_fadairo@yahoo.com?subject=Hey Mayowa, I want to work with you!">
            <p className='contact'> 
                Hire Me
                </p>   
                </a>      
        </div>
    </form>
</section>
<VerticalSpace spaceHeight='1em' />

            </div>
        )
    }
}

export default ContactPage
