import React from 'react'
import SkillIcon from '../img/SkillIcon.svg'

export default function Skill(props) {
    return (
        <div className='skill'>
            <img  src={SkillIcon} alt='skillIcon'></img>
            <h5>{props.name}</h5>
            <p>{props.description}</p>
            
        </div>
    )
}
