import React from 'react'
import Stack from '../element/Stack'

export default function StackSection() {
    return (
        <React.Fragment>
        <h2> Stacks</h2>
            <span className='redLine'></span>
            <div className='stackGrid'>
            <Stack 
            name='Javascript'
            level='60%'/>
            <Stack 
            name= 'PHP/Laravel'
            level='60%'
            />
            <Stack
            name = 'Node.js'
            level='50%' />
            <Stack 
            name = 'HTML'
            level='90%'/>
            <Stack 
            name = 'React.js'
            level='60%'/>
            <Stack 
            name = 'CSS'
            level='75%'/>
            <Stack 
            name = 'Adobe Premier Pro'
            level='75%'/>
            <Stack 
            name = 'Adobe After Effects'
            level='75%'/>
            </div>  
        </React.Fragment>
    )
}
